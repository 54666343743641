import * as React from 'react';

import Post from '../features/Post';
import BoardHeader from '../features/BoardHeader';
import PostForm from '../features/PostForm';


export default function Board() {
    return (
        <>
            <BoardHeader />
            <PostForm />
            <Post />
            <Post />
            <Post />
        </>
    )
}
