import * as React from 'react';

import Box from '@mui/material/Box';

import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import ShowMoreText from "react-show-more-text";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import BookmarkAdd from '@mui/icons-material/BookmarkAdd';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export default function BoardHeader() {
    return (
        <Card>
            <CardHeader
                title="Návrhy a nebo něco s dlouhým názvem"
                titleTypographyProps={{ variant: 'h6' }}
                action={<>
                    <Button startIcon={<BookmarkAdd />}>Oblíbit</Button>
                    <IconButton aria-label="settings">
                        <MoreVertIcon />
                    </IconButton>
                </>}
                subheader="Spravují"
            />
            <CardContent>
                <Typography variant="body2">
                    <ShowMoreText>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. <b><a href="#">Similique
                            unde</a></b> fugit veniam eius, perspiciatis sunt? Corporis qui ducimus quibusdam,
                            aliquam dolore excepturi quae. <b>Distinctio enim at eligendi perferendis in
                                cum quibusdam sed quae, accusantium et aperiam?</b> Quod itaque exercitationem,
                            at ab sequi qui modi delectus quia corrupti alias distinctio nostrum.
                            Minima ex dolor modi inventore sapiente necessitatibus aliquam fuga et.
                        </p>
                        <p> Sed
                            numquam quibusdam at officia sapiente porro maxime corrupti perspiciatis
                            asperiores, exercitationem eius nostrum consequuntur iure aliquam itaque,
                            assumenda et! Quibusdam temporibus beatae doloremque voluptatum doloribus
                            soluta accusamus porro reprehenderit eos inventore facere, fugit, molestiae
                            ab officiis illo voluptates recusandae. Vel dolor nobis eius, ratione atque
                            soluta, aliquam fugit qui iste architecto perspiciatis. Nobis, voluptatem!
                            Cumque, eligendi unde aliquid minus quis sit debitis obcaecati error,
                            delectus quo eius exercitationem tempore.
                        </p>
                        <p>Delectus sapiente, provident
                            corporis dolorum quibusdam aut beatae repellendus est labore quisquam
                            praesentium repudiandae non vel laboriosam quo ab perferendis velit ipsa
                            deleniti modi! Ipsam, illo quod. Nesciunt commodi nihil corrupti cum non
                            fugiat praesentium doloremque architecto laborum aliquid. Quae, maxime
                            recusandae? Eveniet dolore molestiae dicta blanditiis est expedita eius
                            debitis cupiditate porro sed aspernatur quidem, repellat nihil quasi
                            praesentium quia eos, quibusdam provident. Incidunt tempore vel placeat
                            voluptate iure labore, repellendus beatae quia unde est aliquid dolor
                            molestias libero.
                        </p>
                        <p>
                            Reiciendis similique exercitationem consequatur, nobis
                            placeat illo laudantium! Enim perferendis nulla soluta magni error,
                            provident repellat similique cupiditate ipsam, et tempore cumque quod! Qui,
                            iure suscipit tempora unde rerum autem saepe nisi vel cupiditate iusto.
                            Illum, corrupti? Fugiat quidem accusantium nulla.
                        </p>
                        <p>Aliquid inventore commodi
                            reprehenderit rerum reiciendis! Quidem alias repudiandae eaque eveniet
                            cumque nihil aliquam in expedita, impedit quas ipsum nesciunt ipsa ullam
                            consequuntur dignissimos numquam at nisi porro a, quaerat rem repellendus.
                            Voluptates perspiciatis, in pariatur impedit, nam facilis libero dolorem
                            dolores sunt inventore perferendis, aut sapiente modi nesciunt.
                        </p>
                    </ShowMoreText>
                </Typography>
            </CardContent>
        </Card >
    )
}
