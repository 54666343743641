import React from 'react';
import createTheme from "@mui/material/styles/createTheme";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import ThemeProvider from "@mui/material/styles/ThemeProvider";

import OkounAppBar from './features/OkounAppBar';
import Board from './pages/Board';

import './App.css';

import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#5c8adc',
      },
      background: {
        default: '#dfeaff',
      }
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <Box sx={{ display: 'flex' }} className="main">
          <OkounAppBar />
          <Box component="main">
            <Toolbar />
            <Board />
          </Box>
        </Box >
      </StyledEngineProvider>
    </ThemeProvider>
  );
}

export default App;
