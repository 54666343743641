import React, { FC } from "react";
import ReactDOM from "react-dom";

import Paper from "@mui/material/Paper";
import Grid from '@mui/material/Grid';
import Avatar from "@mui/material/Avatar";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CommentIcon from '@mui/icons-material/Comment';
import CardContent from "@mui/material/CardContent";

import './post.css';

const imgLink =
    "https://images.pexels.com/photos/1681010/pexels-photo-1681010.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260";

const Post: FC = () => {
    return (
        <Card>
            <CardHeader
                avatar={
                    <Avatar variant="rounded" src={imgLink} />
                }
                title="Toto Je Autor"
                subheader="Napis příspěvku může být dlouhý"
                action={
                    <IconButton aria-label="settings">
                        <MoreVertIcon />
                    </IconButton>
                }
            />
            <CardContent>
                <Typography className="post-content-tg" variant="body2" paragraph={false}>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
                        luctus ut est sed faucibus. Duis bibendum ac ex vehicula laoreet.
                        Suspendisse congue vulputate lobortis. Pellentesque at interdum
                        tortor.
                    </p>
                    <p> Quisque arcu quam, malesuada vel mauris et, posuere
                        sagittis ipsum. Aliquam ultricies a ligula nec faucibus. In elit
                        metus, efficitur lobortis nisi quis, molestie porttitor metus.
                        Pellentesque et neque risus.
                    </p>
                    <p>Aliquam vulputate, mauris vitae
                        tincidunt interdum, mauris mi vehicula urna, nec feugiat quam
                        lectus vitae ex.
                    </p>
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small" startIcon={<CommentIcon />}>Odpovědět</Button>
            </CardActions>
        </Card>
    );
};

export default Post;
