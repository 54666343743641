import React from 'react';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent'
import useFormControl from '@mui/material/FormControl/useFormControl';
import Button from '@mui/material/Button';

export default function PostForm() {
    const { focused } = useFormControl() || {};
    return (
        <Card>
            <CardContent>
                <TextField
                    placeholder='Co máte na srdci?'
                    fullWidth
                    maxRows={10}
                    multiline
                />
                {
                    focused && <Button>Odeslat</Button>
                }
            </CardContent>
        </Card>
    )
}
